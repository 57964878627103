import React from "react";
import flux from "@aust/react-flux";
import { useTheme } from "@mui/material/styles";

// Local
import Home from "view/home";

const Nav = () => {
  const { status } = flux.sys.useState();
  const theme = useTheme();

  return <div style={style(theme)}>{status === "home" && <Home />}</div>;
};

export default Nav;

const style = (theme) => {
  return {
    display: "flex",
    flex: 1,
    height: "100vh",
    width: "100vw",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  };
};
