import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

import Row from "util/row";
import Logo from "img/logo.png";
import Column from "util/column";

const Home = () => {
  return (
    <Column style={{ overflow: "hidden" }}>
      <div className='bg' />
      <Column justify='flex-start' align='center'>
        <img
          src={Logo}
          alt='logo'
          style={{ height: "10vh", width: "10vh", marginTop: "1rem" }}
          className='animate__animated animate__fadeInDown'
        />
        <h2 className='animate__animated animate__fadeIn'>Davin Walker</h2>

        <p className='animate__animated animate__fadeIn'>
          Hobbyist, Rubyist, Composer, and DragonRider
        </p>
      </Column>

      <Row
        flex={3}
        justify='center'
        align='center'
        className='animate__animated animate__fadeInUp'
      >
        <IconButton
          aria-label='delete'
          color='primary'
          size='large'
          component={Link}
          href='https://www.youtube.com/channel/UC8Uz_vaa07qtlQZ9P2SI20g'
          target='_blank'
          rel='noreferrer'
        >
          <FontAwesomeIcon icon='fa-brands fa-youtube' size='xl' />
        </IconButton>

        <IconButton
          aria-label='delete'
          color='primary'
          size='large'
          component={Link}
          href='https://soundcloud.com/davinwalker'
          target='_blank'
          rel='noreferrer'
        >
          <FontAwesomeIcon icon='fa-brands fa-soundcloud' size='xl' />
        </IconButton>

        <IconButton
          color='primary'
          component={Link}
          href='https://dishcandanty.itch.io/'
          target='_blank'
          rel='noreferrer'
          size='large'
        >
          <FontAwesomeIcon icon='fa-brands fa-itch-io' size='xl' />
        </IconButton>
      </Row>
    </Column>
  );
};

export default Home;
