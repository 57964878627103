import flux from "@aust/react-flux";
import history from "history/hash";

function initialSettings() {
  return {
    status: "home",
    context: "",
    mode: "dark",
  };
}

const store = flux.addStore("sys", initialSettings());
store.register("sys/reset", () => initialSettings);

// ========================================================================
// -- History
// ========================================================================
//  Forward/Back Navigation Controls
history.listen(({ location }) => {
  flux.dispatch("sys/update", {
    status: location.pathname.substring(1),
    context: location.state,
  });
});

// Update Handler
store.register("sys/status", async (_, status, context = {}) => {
  history.push(`/${status}`, context);
});

// Update Replace Handler
store.register("sys/replace", async (_, context = {}) => {
  let status = store.selectState("status");
  history.replace(status, context);
});

// ========================================================================
// -- Store Updates
// ========================================================================
store.register("sys/update", async (_, payload) => {
  return (state) => ({ ...state, ...payload });
});

// ========================================================================
// -- Load
// ========================================================================
store.register("sys/start", async (dispatch) => {
  await dispatch("user/meta");
  dispatch("sys/status", "home");
});
