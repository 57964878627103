import React, { useEffect } from "react";
import flux from "@aust/react-flux";

// Mui
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "animate.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

// Local
import Nav from "view/nav";

// Debug
flux.setOption("displayLogs", false);

// Add
library.add(fas, fab, far);

function App() {
  const mode = flux.sys.useState("mode");

  useEffect(() => {
    flux.dispatch("sys/init");
  }, []);

  // Theme
  const theme = createTheme({
    palette: {
      mode: mode,
    },
    typography: {
      fontFamily: "'Rajdhani', sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Nav />
    </ThemeProvider>
  );
}

export default App;
