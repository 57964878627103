import React from "react";

function Div({
  onClick = null,
  className = "",
  flex = 1,
  direction = null,
  wrap = null,
  justify = null,
  align = null,
  // =========================================================
  // Events
  // =========================================================
  children = null,
  onMouseEnter = null,
  onMouseLeave = null,
  onMouseDown = null,
  // =========================================================

  // =========================================================
  // Animation Helper
  // =========================================================
  anim = null,
  // =========================================================
  // Styles
  // =========================================================
  style = {},
}) {
  let defaultStyle = {
    display: "flex",
    flex: flex,
    flexDirection: direction,
    justifyContent: justify,
    alignItems: align,
  };

  if (wrap) defaultStyle.flexWrap = wrap;

  if (anim) className += ` animate__animated animate__${anim}`;

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      style={{ ...defaultStyle, ...style }}
    >
      {children}
    </div>
  );
}

export default Div;
